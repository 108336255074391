import { apiMethods } from '@services/methods'
import {
  baseV1URL,
  baseV2URL,
  micropd02,
  authenticationURL
} from '@configs/apis'

const urlBase = `${baseV1URL}/commerces`

const endpoints = {
  amounts: `${urlBase}/amounts`,
  dashboard: `${urlBase}/dashboard`,
  commerceList: `${baseV2URL}/superadmin/commerces/auto-completion`,
  commercesTypes: `${baseV2URL}/superadmin/commerces/list-categories`,
  add: `${baseV2URL}/superadmin/commerces/register`,
  list: `${baseV2URL}/superadmin/commerces/list`,
  block: `${baseV2URL}/superadmin/commerces/blocked`,
  update: `${baseV2URL}/superadmin/commerces/update`,

  getExtensionsFileSuperadmin: `${baseV1URL}/superadmin/commerce/excel-extension`,
  getExtensionsFile: `${baseV1URL}/admin/commerce/excel-extension`,
  postExcelSuperadmin: `${baseV1URL}/superadmin/commerce/add-extension`,
  postExcel: `${baseV1URL}/admin/commerce/add-extension`,
  getListExtensionsSuperadmin: `${baseV1URL}/superadmin/commerce/list-extension`,
  getListExtensionsAdmin: `${baseV1URL}/admin/commerce/list-extension`,
  deleteExtension: `${baseV1URL}/superadmin/commerce/remove-extension`,
  updateExtensionSuperadmin: `${baseV1URL}/superadmin/commerce/update-extension`,
  updateExtension: `${baseV1URL}/admin/commerce/update-extension`,
  addCommercesExtensionSuperadmin: `${baseV1URL}/superadmin/commerce/add-extension`,
  addCommercesExtension: `${baseV1URL}/admin/commerce/add-extension`,
  deleteCommercesExtensionSuperadmin: `${baseV1URL}/superadmin/commerce/remove-extension`,
  deleteCommercesExtension: `${baseV1URL}/admin/commerce/remove-extension`,
  whitelist: `${baseV1URL}/superadmin/commerce/whitelist`,
  rechargeCommerce: `${baseV2URL}/commerces/recharges`,
  transactionsApprove: `${baseV2URL}/superadmin/commerces/withdraw/mark-paid`,

  getPatientInfo: `${baseV1URL}/admin/commerce/salud-chacao-request`,
  getPatientExtension: `${baseV1URL}/admin/commerce/search-extension`,
  getPatientInfoSuperadmin: `${baseV1URL}/superadmin/commerce/salud-chacao-request`,
  getPatientExtensionSuperadmin: `${baseV1URL}/superadmin/commerce/search-extension/commerce/644c45a92923275ca70bbbba/id`,

  superAdminCheckCommerceExtension: `${baseV2URL}/superadmin/commerces/payments/transfers/check-id`,
  adminCheckCommerceExtension: `${baseV1URL}/admin/commerces/payments/transfers/check-id`,

  assignAssociatesCommerce: `${baseV1URL}/superadmin/commerce/associates`,
  getAssociatesCommerce: `${baseV1URL}/admin/commerce/associates`,

  getAssociatesCommerceMetrics: `${baseV1URL}/admin/commerce/associates/transaction-metrics`,

  checkIfCommerceMobilePaymentWasAssignedToCustomer: `${baseV1URL}/admin/commerce/payment/mp/check`,
  debitUserFundsToCreditCommerce: `${baseV1URL}/admin/commerce/payment/mp`,

  notifyCommerceMobilePayment: `${micropd02}/admin/mobile-payment/bdv/notify`,
  notifyCommerceBankTransfer: `${micropd02}/admin/bank-movement/notify/commerce`,

  getCommerceRolesService: `${authenticationURL}/superadmin/operational-roles/commerce/roles`
}

export const getCommerceRolesService = async (body) => {
  try {
    const { ok, res, error } = await apiMethods.authPostFetch({
      url: endpoints.getCommerceRolesService,
      body
    })
    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const checkIfCommerceMobilePaymentWasAssignedToCustomerService = async (
  body
) => {
  try {
    const { ok, res, error } = await apiMethods.authPostFetch({
      url: endpoints.checkIfCommerceMobilePaymentWasAssignedToCustomer,
      body
    })
    return { ok, res, error }
  } catch (error) {
    return error
  }
}
export const debitUserFundsToCreditCommerceService = async (body) => {
  try {
    const { ok, res, error } = await apiMethods.authPostFetch({
      url: endpoints.debitUserFundsToCreditCommerce,
      body
    })
    return { ok, res, error }
  } catch (error) {
    return error
  }
}
export const notifyCommerceMobilePaymentService = async (body) => {
  try {
    const { ok, res, error } = await apiMethods.authPostFetch({
      url: endpoints.notifyCommerceMobilePayment,
      body
    })
    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const notifyCommerceBankTransferService = async (body) => {
  try {
    const { ok, res, error } = await apiMethods.authPostFetch({
      url: endpoints.notifyCommerceBankTransfer,
      body
    })
    return { ok, res, error }
  } catch (error) {
    return error
  }
}

// Associated commerces
export const getAssociatesCommerceMetricsService = async (body) => {
  try {
    const { ok, res, error } = await apiMethods.authPostFetch({
      url: endpoints.getAssociatesCommerceMetrics,
      body
    })
    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const assignAssociatesCommerceService = async (body) => {
  try {
    const { ok, res, error } = await apiMethods.authPostFetch({
      url: endpoints.assignAssociatesCommerce,
      body
    })
    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const getAssociatesCommerceService = async (body) => {
  try {
    const { ok, res, error } = await apiMethods.authGetFetch(
      endpoints.getAssociatesCommerce
    )
    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const superAdminCheckCommerceExtension = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.superAdminCheckCommerceExtension,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const adminCheckCommerceExtension = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.adminCheckCommerceExtension,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const getPatientInfoService = async (id) => {
  try {
    const url = `${endpoints.getPatientInfo}/${id}`
    const { ok, res, error } = await apiMethods.authGetFetch(url)
    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const getPatientExtensionService = async (id) => {
  try {
    const url = `${endpoints.getPatientExtension}/${id}`
    const { ok, res, error } = await apiMethods.authGetFetch(url)
    return { ok, res, error }
  } catch (error) {
    return error
  }
}
export const getPatientInfoServiceSuperadmin = async (id) => {
  try {
    const url = `${endpoints.getPatientInfoSuperadmin}/${id}`
    const { ok, res, error } = await apiMethods.authGetFetch(url)
    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const getPatientExtensionServiceSuperadmin = async (id) => {
  try {
    const url = `${endpoints.getPatientExtensionSuperadmin}/${id}`
    const { ok, res, error } = await apiMethods.authGetFetch(url)
    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const getPDExtensionServiceSuperadmin = async ({
  commerce,
  usuario
}) => {
  try {
    const url = `${baseV1URL}/superadmin/commerce/search-extension/commerce/${commerce}/id/${usuario}`
    const { ok, res, error } = await apiMethods.authGetFetch(url)
    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const transactionsApproveServices = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.transactionsApprove,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const rechargeCommerceFundsServices = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.rechargeCommerce,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const enableCommerceToUseExtensionsServices = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.whitelist,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const updateCommerceExtensionSuperadminService = async (body) => {
  try {
    const response = await apiMethods.authPatchFetch({
      url: endpoints.updateExtensionSuperadmin,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const updateCommerceExtensionService = async (body) => {
  try {
    const response = await apiMethods.authPatchFetch({
      url: endpoints.updateExtension,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const deleteCommerceExtensionSuperadminService = async (body) => {
  try {
    const response = await apiMethods.authDeleteFetch({
      url: endpoints.deleteCommercesExtensionSuperadmin,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const deleteCommerceExtensionService = async (body) => {
  try {
    const response = await apiMethods.authDeleteFetch({
      url: endpoints.deleteCommercesExtension,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const addCommercesExtensionSuperadminService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.addCommercesExtensionSuperadmin,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const addCommercesExtensionService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.addCommercesExtension,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const uploadFileExtensionsSuperadminServices = async (body) => {
  try {
    const response = await apiMethods.authPostFetchFormData({
      url: endpoints.postExcelSuperadmin,
      formData: body
    })
    return response
  } catch (error) {
    return error
  }
}

export const uploadFileExtensionsServices = async (body) => {
  try {
    const response = await apiMethods.authPostFetchFormData({
      url: endpoints.postExcel,
      formData: body
    })
    return response
  } catch (error) {
    return error
  }
}

export const exportCommercesExtensionsSuperadminServices = async (id) => {
  try {
    const url = `${endpoints.getExtensionsFileSuperadmin}/${id}`
    const { ok, res, error } = await apiMethods.authGetFetch(url)
    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const exportCommercesExtensionsServices = async (id) => {
  try {
    const url = `${endpoints.getExtensionsFile}`
    const { ok, res, error } = await apiMethods.authGetFetch(url)
    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const getExtensionsSuperadminService = async (body) => {
  try {
    const { ok, res, error } = await apiMethods.authPostFetch({
      url: endpoints.getListExtensionsSuperadmin,
      body
    })
    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const getExtensionsService = async (body) => {
  try {
    const { ok, res, error } = await apiMethods.authPostFetch({
      url: endpoints.getListExtensionsAdmin,
      body
    })
    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const updateCommerceService = async (body) => {
  try {
    const response = await apiMethods.authPatchFetch({
      url: `${endpoints.update}`,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const blockCommerceService = async (body) => {
  try {
    const response = await apiMethods.authPatchFetch({
      url: `${endpoints.block}`,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const getCommercesService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.list,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const getCommercesListService = async (body) => {
  try {
    const response = await apiMethods.authGetFetch(endpoints.list)
    return response
  } catch (error) {
    return error
  }
}

export const addCommerceService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.add,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const commerceListAutocomplete = async (body) => {
  const { ok, res, error } = await apiMethods.authPostFetch({
    url: endpoints.commerceList,
    body
  })
  return { ok, res, error }
}

export const commerceAmounts = async () => {
  const { ok, res, error } = await apiMethods.authGetFetch(endpoints.amounts)
  return { ok, res, error }
}

export const commerceDashboard = async ({
  startDay: start_day,
  period = 'weekly'
}) => {
  const body = { start_day, period }
  const { ok, res, error } = await apiMethods.authPostFetch({
    url: endpoints.dashboard,
    body
  })
  return { ok, res, error }
}

export const commerceTypesAutocomplete = async () => {
  const { ok, res, error } = await apiMethods.authGetFetch(
    endpoints.commercesTypes
  )
  return { ok, res, error }
}
