import {
  Activity,
  AlertOctagon,
  Bell,
  Bluetooth,
  BookOpen,
  CheckSquare,
  Circle,
  Clipboard,
  CreditCard,
  Download,
  File,
  FileText,
  Film,
  Image,
  List,
  LogOut,
  Monitor,
  Package,
  PieChart,
  Smartphone,
  Truck,
  User,
  Users
} from 'react-feather'

import { getCommerceData } from '@utils'

export const initialOperator = () => {
  const operator = JSON.parse(window.localStorage.getItem('operator'))
  const isSuperadmin = operator?.commerce_associated?.category === 'pagodirecto'

  const ability = operator.ability

  return isSuperadmin
}

export const pdLevel = () => {
  const operator = JSON.parse(window.localStorage.getItem('operator'))
  const ability = operator.ability

  let pagodirectoLevel2Exists = false

  // Supongamos que 'actions' es tu array de objetos
  for (let i = 0; i < actions.length; i++) {
    if (actions[i].action === 'pagodirecto-level-2') {
      pagodirectoLevel2Exists = true
      break
    }
  }

  return pagodirectoLevel2Exists
}

// Reports

const reportsUsersOptions = [
  {
    value: 'verifiedRegisteredUsers',
    label: 'Registro de usuarios verificados'
  },
  {
    value: 'unverifiedRegisteredUsers',
    label: 'Registro de usuarios no verificados'
  },
  {
    value: 'unverifiedRegisterdUsersByOffice',
    label: 'Registro por taquilla de usuarios no verificados'
  },
  {
    value: 'transactionsCustomer',
    label: 'Transacciones de usuarios'
  },
  {
    value: 'userActivities',
    label: 'Actividad de Usuarios'
  },
  {
    value: 'premiumTicketsQtyByUser',
    label: 'Tickets premium por usuario'
  },
  {
    value: 'parkingLogsUserPhysicalAccess',
    label: 'Logs de tickets físicos por usuario'
  },
  {
    value: 'userParkingTickets',
    label: 'Tickets de estacionamiento por usuario'
  },
  {
    value: 'transactionsSchools',
    label: 'Transacciones QR de escuelas asociadas'
  },
  {
    value: 'walletRecharges',
    label: 'Recargas al wallet'
  },
  {
    value: 'transactionsCreditPremium',
    label: 'Transacciones de crédito premium'
  },
  {
    value: 'approvedRechargesSuperadmin',
    label: 'Recargas aprobadas por superadmin'
  }
]

const reportsParkingLotOptions = [
  {
    value: 'ticketsBuilding',
    label: 'Tickets generados'
  },
  {
    value: 'parkingLogs',
    label: 'Logs de accesos por estacionamientos'
  },
  {
    value: 'parkingLogsBuildingPhysicalAccess',
    label: 'Logs de tickets físicos por estacionamiento'
  },
  {
    value: 'tickets',
    label: 'Tickets generados en todos los estacionamientos'
  }
]

const reportsParkingMetersOptions = [
  {
    value: 'parkingMeters',
    label: 'Tickets de parquímetros'
  }
]

const reportsCommercesOptions = [
  {
    value: 'transactionsCommercesTransfers',
    label: 'Transferencias de comercios'
  }
]

const reportsServicesOptions = [
  {
    value: 'servicePayment',
    label: 'Pago de servicios'
  }
]

const reportsTollsOptions = [
  {
    value: 'tollTickets',
    label: 'Tickets de peajes'
  }
]

const reportsAdministrativeOptions = [
  {
    value: 'ticketsBuilding',
    label: 'Tickets generados'
  }
]

const userSearchIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='icon icon-tabler icons-tabler-outline icon-tabler-user-search'
  >
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0' />
    <path d='M6 21v-2a4 4 0 0 1 4 -4h1.5' />
    <path d='M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0' />
    <path d='M20.2 20.2l1.8 1.8' />
  </svg>
)

const ticket = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='icon icon-tabler icons-tabler-outline icon-tabler-ticket'
  >
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M15 5l0 2' />
    <path d='M15 11l0 2' />
    <path d='M15 17l0 2' />
    <path d='M5 5h14a2 2 0 0 1 2 2v3a2 2 0 0 0 0 4v3a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-3a2 2 0 0 0 0 -4v-3a2 2 0 0 1 2 -2' />
  </svg>
)

const todayIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='icon icon-tabler icons-tabler-outline icon-tabler-clock-24'
  >
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M3 12a9 9 0 0 0 5.998 8.485m12.002 -8.485a9 9 0 1 0 -18 0' />
    <path d='M12 7v5' />
    <path d='M12 15h2a1 1 0 0 1 1 1v1a1 1 0 0 1 -1 1h-1a1 1 0 0 0 -1 1v1a1 1 0 0 0 1 1h2' />
    <path d='M18 15v2a1 1 0 0 0 1 1h1' />
    <path d='M21 15v6' />
  </svg>
)

const transfersIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='icon icon-tabler icons-tabler-outline icon-tabler-transfer'
  >
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M20 10h-16l5.5 -6' />
    <path d='M4 14h16l-5.5 6' />
  </svg>
)

const consolidatePositionIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='icon icon-tabler icons-tabler-outline icon-tabler-cash-banknote'
  >
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0' />
    <path d='M3 6m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z' />
    <path d='M18 12l.01 0' />
    <path d='M6 12l.01 0' />
  </svg>
)

const wallet = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='icon icon-tabler icons-tabler-outline icon-tabler-wallet'
  >
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M17 8v-3a1 1 0 0 0 -1 -1h-10a2 2 0 0 0 0 4h12a1 1 0 0 1 1 1v3m0 4v3a1 1 0 0 1 -1 1h-12a2 2 0 0 1 -2 -2v-12' />
    <path d='M20 12v4h-4a2 2 0 0 1 0 -4h4' />
  </svg>
)

const transactionsIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='icon icon-tabler icons-tabler-outline icon-tabler-credit-card-pay'
  >
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M12 19h-6a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v4.5' />
    <path d='M3 10h18' />
    <path d='M16 19h6' />
    <path d='M19 16l3 3l-3 3' />
    <path d='M7.005 15h.005' />
    <path d='M11 15h2' />
  </svg>
)

const billIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='icon icon-tabler icons-tabler-outline icon-tabler-receipt-2'
  >
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2' />
    <path d='M14 8h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5m2 0v1.5m0 -9v1.5' />
  </svg>
)

const mobileTransferIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='icon icon-tabler icons-tabler-outline icon-tabler-device-mobile-share'
  >
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M12 21h-4a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8' />
    <path d='M11 4h2' />
    <path d='M16 22l5 -5' />
    <path d='M21 21.5v-4.5h-4.5' />
    <path d='M12 17v.01' />
  </svg>
)

const buildingIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='icon icon-tabler icons-tabler-outline icon-tabler-building'
  >
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M3 21l18 0' />
    <path d='M9 8l1 0' />
    <path d='M9 12l1 0' />
    <path d='M9 16l1 0' />
    <path d='M14 8l1 0' />
    <path d='M14 12l1 0' />
    <path d='M14 16l1 0' />
    <path d='M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16' />
  </svg>
)

const sspossIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='icon icon-tabler icons-tabler-outline icon-tabler-device-imac'
  >
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M3 4a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v12a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1v-12z' />
    <path d='M3 13h18' />
    <path d='M8 21h8' />
    <path d='M10 17l-.5 4' />
    <path d='M14 17l.5 4' />
  </svg>
)

const digitalizeTicket = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='icon icon-tabler icons-tabler-outline icon-tabler-scan'
  >
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M4 7v-1a2 2 0 0 1 2 -2h2' />
    <path d='M4 17v1a2 2 0 0 0 2 2h2' />
    <path d='M16 4h2a2 2 0 0 1 2 2v1' />
    <path d='M16 20h2a2 2 0 0 0 2 -2v-1' />
    <path d='M5 12l14 0' />
  </svg>
)

const addNewUser = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='icon icon-tabler icons-tabler-outline icon-tabler-user-plus'
  >
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0' />
    <path d='M16 19h6' />
    <path d='M19 16v6' />
    <path d='M6 21v-2a4 4 0 0 1 4 -4h4' />
  </svg>
)

const commerceAudit = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='icon icon-tabler icons-tabler-outline icon-tabler-home-dollar'
  >
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M19 10l-7 -7l-9 9h2v7a2 2 0 0 0 2 2h6' />
    <path d='M9 21v-6a2 2 0 0 1 2 -2h2c.387 0 .748 .11 1.054 .3' />
    <path d='M21 15h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5' />
    <path d='M19 21v1m0 -8v1' />
  </svg>
)

const enableRecharge = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='icon icon-tabler icons-tabler-outline icon-tabler-file-check'
  >
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M14 3v4a1 1 0 0 0 1 1h4' />
    <path d='M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z' />
    <path d='M9 15l2 2l4 -4' />
  </svg>
)

//

const ability = {
  MANAGER: 'manager-level-1',
  SALES: 'sales-level-1',
  MARKETING: 'marketing-level-1',
  ATC: 'atc-level-1',
  SATC: 'satc-level-1',
  FINANCE: 'finance-level-1',
  SYSTEM: 'sistem-level-1',
  PROMOTER: 'promoter-level-1'
}

const userVertical = {
  vertical: [
    {
      header: 'Estadísticas',
      action: [ability.MANAGER, ability.SALES, ability.MARKETING],
      resource: 'Common'
    },

    {
      id: 'reports-user',
      title: 'Reportes',

      action: [ability.MANAGER, ability.SALES, ability.MARKETING],
      resource: 'Common',
      icon: <Download size={20} />,
      navLink: 'users/users-reports'
    },

    {
      header: 'Administración de usuarios',
      action: [ability.MANAGER, ability.ATC, ability.SATC],
      resource: 'Common'
    },

    {
      id: 'search-user',
      title: 'Buscar usuario',

      action: [ability.MANAGER, ability.ATC, ability.SATC],
      resource: 'Common',
      icon: <User size={20} />,
      navLink: 'users/search-users'
    },

    {
      id: 'extensions-user',
      title: 'Extensiones',

      action: [ability.MANAGER, ability.ATC, ability.SATC],
      resource: 'Common',
      icon: <Users size={20} />,
      navLink: 'users/extensions'
    },
    {
      id: 'Vehículos-user',
      title: 'Vehículos',

      action: [ability.MANAGER, ability.ATC, ability.SATC],
      resource: 'Common',
      icon: <Truck size={20} />,
      navLink: 'users/vehicles'
    },

    // {
    //   id: 'validate-user-event',
    //   title: 'Historial de tickets',

    //   action: 'level-0',
    //   resource: 'Common',
    //   icon: <FileText size={20} />,
    //   navLink: 'users/validate-event'
    // },

    // {
    //   header: 'Centro de notificaciones',
    //   action: 'pagodirecto-level-2',
    //   resource: 'Common'
    // },
    // {
    //   id: 'standar-notification-user',
    //   title: 'Estándar',

    //   action: 'pagodirecto-level-2',
    //   resource: 'Common',
    //   icon: <FileText size={20} />,
    //   navLink: 'users/notifications'
    // },

    {
      header: 'Publicidad en ticket',
      action: [ability.MANAGER, ability.SALES, ability.MARKETING],
      resource: 'Common'
    },
    {
      id: 'advertising-users-dashboard',
      title: 'Resumen',
      action: [ability.MANAGER],
      resource: 'Common',
      icon: <PieChart size={20} />,
      navLink: '/users/advertising-dashboard'
    },
    {
      id: 'advertising-user',
      title: 'Crear publicidad',
      action: [ability.MANAGER, ability.SALES, ability.MARKETING],
      resource: 'Common',
      icon: <Image size={20} />,
      navLink: '/users/advertising'
    },

    {
      header: 'Cupones',
      action: [ability.MANAGER, ability.ATC, ability.MARKETING, ability.SATC],
      resource: 'Common'
    },
    {
      id: 'notification-center',
      title: 'Notificaciones',
      action: [ability.MANAGER, ability.ATC, ability.MARKETING, ability.SATC],
      resource: 'Common',
      icon: <Bell size={20} />,
      navLink: '/users/notifications'
    },

    {
      id: 'cupon-list-public',
      title: 'Cupones',
      action: [ability.MANAGER, ability.MARKETING],
      resource: 'Common',
      icon: ticket,
      navLink: '/parking-lot/coupons'
    },

    {
      id: 'cupon-list-history',
      title: 'Historial de actividad',
      action: [ability.MANAGER, ability.ATC, ability.MARKETING, ability.SATC],
      resource: 'Common',
      icon: <FileText size={20} />,
      navLink: '/parking-lot/coupons-uses-history'
    },

    {
      header: 'Configuración',
      action: [
        ability.MANAGER,
        ability.SALES,
        ability.MARKETING,
        ability.ATC,
        ability.SATC
      ],
      resource: 'Common'
    },
    {
      id: 'logout-user',
      title: 'Cerrar sesión',

      action: [
        ability.MANAGER,
        ability.SALES,
        ability.MARKETING,
        ability.ATC,
        ability.SATC
      ],
      resource: 'Common',
      icon: <LogOut size={12} />,
      navLink: 'commerces/logout'
    },
    {
      id: 'span-a',
      title: '',

      action: 'pagodirecto-level-2',
      resource: 'Common',
      navLink: 'commerces/logout'
    },
    {
      id: 'span-a',
      title: '',

      action: 'pagodirecto-level-2',
      resource: 'Common',
      navLink: 'commerces/logout'
    }
  ],
  label: 'Usuario',
  homeRoute: '/users/search-users',
  isActive: true
}

const eventsVertical = {
  vertical: [
    {
      header: 'Administración de eventos',
      action: [ability.MANAGER, ability.PROMOTER, ability.ATC, ability.SATC],
      resource: 'Common'
    },

    {
      id: 'search-user-event',
      title: 'Buscar usuario',

      action: [ability.MANAGER, ability.PROMOTER, ability.ATC, ability.SATC],
      resource: 'Common',
      icon: userSearchIcon,
      navLink: 'users/event'
    },
    {
      id: 'register-user-event',
      title: 'Registrar usuario',

      action: [ability.MANAGER, ability.PROMOTER, ability.ATC, ability.SATC],
      resource: 'Common',
      icon: addNewUser,
      navLink: 'users/register-user'
    },
    {
      id: 'buy-user-event',
      title: 'Comprar evento',

      action: [ability.MANAGER, ability.PROMOTER, ability.ATC, ability.SATC],
      resource: 'Common',
      icon: <CreditCard size={20} />,
      navLink: 'users/pay-event'
    },
    {
      id: 'validate-user-event',
      title: 'Validar evento',

      action: [ability.MANAGER, ability.PROMOTER, ability.ATC, ability.SATC],
      resource: 'Common',
      icon: <CheckSquare size={20} />,
      navLink: 'users/validate-event'
    },

    {
      header: 'Configuración',
      action: [ability.MANAGER, ability.ATC, ability.SATC, ability.PROMOTER],
      resource: 'Common'
    },
    {
      id: 'logout-user',
      title: 'Cerrar sesión',

      action: [ability.MANAGER, ability.ATC, ability.SATC, ability.PROMOTER],
      resource: 'Common',
      icon: <LogOut size={12} />,
      navLink: 'commerces/logout'
    }
  ],
  label: 'Eventos',
  homeRoute: '/users/event',
  isActive: false
}

const associatedExtension = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='icon icon-tabler icons-tabler-outline icon-tabler-brand-etsy'
  >
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M14 12h-5' />
    <path d='M3 3m0 5a5 5 0 0 1 5 -5h8a5 5 0 0 1 5 5v8a5 5 0 0 1 -5 5h-8a5 5 0 0 1 -5 -5z' />
    <path d='M15 16h-5a1 1 0 0 1 -1 -1v-6a1 1 0 0 1 1 -1h5' />
  </svg>
)

const payExtensionIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='icon icon-tabler icons-tabler-outline icon-tabler-qrcode'
  >
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M4 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z' />
    <path d='M7 17l0 .01' />
    <path d='M14 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z' />
    <path d='M7 7l0 .01' />
    <path d='M4 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z' />
    <path d='M17 7l0 .01' />
    <path d='M14 14l3 0' />
    <path d='M20 14l0 .01' />
    <path d='M14 14l0 3' />
    <path d='M14 20l3 0' />
    <path d='M17 17l3 0' />
    <path d='M20 17l0 3' />
  </svg>
)

const walletVertical = {
  vertical: [
    {
      id: 'wallet-status',
      header: 'Estadísticas',
      action: [ability.MANAGER, ability.FINANCE],
      resource: 'Common'
    },
    {
      id: 'summary-wallet',
      title: 'Resumen',

      action: [ability.MANAGER, ability.FINANCE],
      resource: 'Common',
      icon: <PieChart size={20} />,
      navLink: '/commerces/dashboard'
    },
    {
      id: 'consolidate-position-wallet',
      title: 'Posición consolidada',

      action: [ability.MANAGER, ability.FINANCE],
      resource: 'Common',
      icon: consolidatePositionIcon,
      navLink: '/wallet/consolidate-position'
    },
    {
      id: 'transfers-wallet',
      title: 'Transferencias',

      action: [ability.MANAGER, ability.FINANCE],
      resource: 'Common',
      icon: transfersIcon,
      navLink: '/wallet/transfers'
    },
    {
      id: 'transfers-history-wallet',
      title: 'Historial transferencias',

      action: [ability.MANAGER, ability.FINANCE],
      resource: 'Common',
      icon: <FileText size={20} />,
      navLink: '/wallet/transfers-history'
    },
    {
      id: 'today-movements-wallet',
      title: 'Movimientos del día',

      action: [ability.MANAGER, ability.FINANCE],
      resource: 'Common',
      icon: todayIcon,
      navLink: '/wallet/today-movements'
    },
    {
      id: 'accounts-wallet',
      title: 'Visualizar cuentas',

      action: [ability.MANAGER, ability.FINANCE],
      resource: 'Common',
      icon: wallet,
      navLink: '/wallet/accounts'
    },

    {
      id: 'accounts-wallet-audit',
      title: 'Auditoria de cuentas ',

      action: 'pagodirecto-level-2 ',
      resource: 'Common',
      icon: commerceAudit,
      navLink: '/wallet/audit '
    },

    {
      id: 'accounts-wallet-pd-audit',
      title: 'Wallet',

      action: 'pagodirecto-level-2',
      resource: 'Common',
      icon: commerceAudit,
      navLink: '/commerces/wallet'
    },

    {
      header: 'En tiempo real',
      action: [ability.MANAGER, ability.ATC, ability.SATC],
      resource: 'Common'
    },
    {
      id: 'movimientos-user-wallet',
      title: 'Movimientos',

      action: [ability.MANAGER, ability.ATC, ability.SATC],
      resource: 'Common',
      icon: transactionsIcon,
      navLink: 'users/transactions'
    },
    {
      header: 'Recargas al wallet',
      action: [ability.MANAGER, ability.ATC, ability.SATC],
      resource: 'Common'
    },
    {
      id: 'movimientos-user-recharge-wallet',
      title: 'Habilitar Recarga',

      action: [ability.MANAGER, ability.ATC, ability.SATC],
      resource: 'Common',
      icon: enableRecharge,
      navLink: 'users/enable-recharge'
    },
    {
      id: 'user-approve-transactions-wallet',
      title: 'Aprobar Transacciones',

      action: [ability.MANAGER, ability.ATC, ability.SATC],
      resource: 'Common',
      icon: <CheckSquare size={20} />,
      navLink: 'users/approve-transactions'
    },

    {
      id: 'movimientos-user-pago-mobile-wallet',
      title: 'Mov. Pago móvil',

      action: [ability.MANAGER, ability.ATC, ability.SATC],
      resource: 'Common',
      icon: mobileTransferIcon,
      navLink: 'users/mobile-paid'
    },
    {
      id: 'movimientos-user-pago-transfers',
      title: 'Mov. Transferencias',

      action: [ability.MANAGER, ability.ATC, ability.SATC],
      resource: 'Common',
      icon: <FileText size={20} />,
      navLink: 'users/transfers'
    },
    {
      header: 'Facturación',
      action: [ability.MANAGER, ability.FINANCE],
      resource: 'Common'
    },
    {
      id: 'billing-history',
      title: 'Facturas',

      action: [ability.MANAGER, ability.FINANCE],
      resource: 'Common',
      icon: billIcon,
      navLink: 'wallet/billing'
    },
    {
      id: 'billing-history-user',
      title: 'Historial de facturas',

      action: [ability.MANAGER, ability.FINANCE],
      resource: 'Common',
      icon: <FileText size={20} />,
      navLink: 'wallet/history'
    },

    {
      header: 'Configuración',
      action: [ability.MANAGER, ability.ATC, ability.SATC, ability.FINANCE],
      resource: 'Common'
    },
    {
      id: 'logout-wallet',
      title: 'Cerrar sesión',

      action: [ability.MANAGER, ability.ATC, ability.SATC, ability.FINANCE],
      resource: 'Common',
      icon: <LogOut size={12} />,
      navLink: 'commerces/logout'
    },
    {
      id: 'span-a-wallet',
      title: '',

      action: 'pagodirecto-level-0',
      resource: 'Common',
      navLink: 'commerces/logout'
    }
  ],
  label: 'Wallet',
  homeRoute: '/wallet/consolidate-position',
  isActive: false
}

const commercesVertical = {
  vertical: [
    {
      header: 'Estadísticas',
      action: [ability.MANAGER],
      resource: 'Common'
    },

    {
      id: 'reports-user-commerces',
      title: 'Reportes',

      action: [ability.MANAGER],
      resource: 'Common',
      icon: <Download size={20} />,
      navLink: 'commerces/commerces-reports'
    },

    {
      header: 'Administración comercios',
      action: [
        ability.MANAGER,
        ability.FINANCE,
        ability.SALES,
        ability.SATC,
        ability.ATC
      ],
      resource: 'Common'
    },
    {
      id: 'commerces-list-user',
      title: 'Comercios',

      action: [ability.MANAGER, ability.FINANCE, ability.SALES, ability.SATC],
      resource: 'Common',
      icon: buildingIcon,
      navLink: '/commerces/list'
    },

    {
      action: 'pagodirecto-level-2',
      resource: 'Common',
      icon: <Users size={20} />,
      navLink: '/commerces/commerces-extensions'
    },
    {
      id: 'commerces-extensions-add',
      title: 'Asignar extensión',

      action: 'pagodirecto-level-2',
      resource: 'Common',
      icon: associatedExtension,
      navLink: '/commerces/add-extension'
    },
    {
      id: 'commerces-extensions-pay',
      title: 'Cobrar',

      action: 'pagodirecto-level-2',
      resource: 'Common',
      icon: payExtensionIcon,
      navLink: '/commerces/pay-extension'
    },
    // {
    //   id: 'commerces-extensions-add-accs',
    //   title: 'Asignar extensión Accs',

    //   action: 'pagodirecto-level-2',
    //   resource: 'Common',
    //   icon: <Users size={20} />,
    //   navLink: '/commerces/add-extension-accs'
    // },
    {
      id: 'movements-commerces-user',
      title: 'Movimientos',

      action: [
        ability.MANAGER,
        ability.FINANCE,
        ability.SALES,
        ability.SATC,
        ability.ATC
      ],
      resource: 'Common',
      icon: <FileText size={20} />,
      navLink: '/commerces/commerces-transactions'
    },
    {
      id: 'reports-user',
      title: 'Historial de retiros',

      action: [ability.MANAGER, ability.FINANCE, ability.SALES],
      resource: 'Common',
      icon: transactionsIcon,
      navLink: 'commerces/withdrawal-history'
    },

    {
      header: 'Extensiones',
      action: [
        ability.MANAGER,
        ability.FINANCE,
        ability.SALES,
        ability.PROMOTER
      ],
      resource: 'Common'
    },
    {
      id: 'commerces-extensions',
      title: 'Listado',

      action: [
        ability.MANAGER,
        ability.FINANCE,
        ability.SALES,
        ability.PROMOTER
      ],
      resource: 'Common',
      icon: <Users size={20} />,
      navLink: '/commerces/commerces-extensions'
    },
    {
      id: 'commerces-extensions-add',
      title: 'Asignar',

      action: [
        ability.MANAGER,
        ability.FINANCE,
        ability.SALES,
        ability.PROMOTER
      ],
      resource: 'Common',
      icon: <Users size={20} />,
      navLink: '/commerces/add-extension'
    },
    {
      id: 'commerces-extensions-pay',
      title: 'Pagar',

      action: [
        ability.MANAGER,
        ability.FINANCE,
        ability.SALES,
        ability.PROMOTER
      ],
      resource: 'Common',
      icon: <Users size={20} />,
      navLink: '/commerces/pay-extension'
    },

    {
      header: 'Administración ',
      action: [ability.MANAGER, ability.FINANCE, ability.SALES, ability.SATC],
      resource: 'Common'
    },
    {
      id: 'work-team-commerces',
      title: 'Equipo de trabajo',

      action: [ability.MANAGER, ability.FINANCE, ability.SALES, ability.SATC, 'pagodirecto-level-2'],
      resource: 'Common',
      icon: <User size={20} />,
      navLink: '/superadmin/commerces/work-team'
    },
    {
      header: 'Configuración',
      action: [
        ability.MANAGER,
        ability.SALES,
        ability.MARKETING,
        ability.ATC,
        ability.SATC,
        ability.FINANCE,
        ability.PROMOTER
      ],
      resource: 'Common'
    },
    {
      id: 'logout-user',
      title: 'Cerrar sesión',

      action: [
        ability.MANAGER,
        ability.SALES,
        ability.MARKETING,
        ability.ATC,
        ability.SATC,
        ability.FINANCE,
        ability.PROMOTER
      ],
      resource: 'Common',
      icon: <LogOut size={12} />,
      navLink: 'commerces/logout'
    }
  ],
  label: 'Comercio',
  homeRoute: '/commerces/admin-dashboard',
  isActive: false
}

const posVertical = {
  vertical: [
    {
      header: 'Admin',
      action: [ability.MANAGER, ability.SYSTEM],
      resource: 'Common'
    },
    {
      id: 'sspos-pos',
      title: 'Taquillas',

      action: [ability.MANAGER, ability.SYSTEM],
      resource: 'Common',
      icon: sspossIcon,
      navLink: '/sspos/list'
    },
    {
      header: 'Configuración',
      action: [ability.MANAGER, ability.SYSTEM],
      resource: 'Common'
    },
    {
      id: 'logout-wallet',
      title: 'Cerrar sesión',

      action: [ability.MANAGER, ability.SYSTEM],
      resource: 'Common',
      icon: <LogOut size={12} />,
      navLink: 'commerces/logout'
    }
  ],
  label: 'POS',
  homeRoute: '/sspos/list',
  isActive: false
}

const parkingLotVertical = {
  vertical: [
    {
      header: 'Estadísticas',
      action: [
        ability.MANAGER,
        ability.FINANCE,
        ability.SALES,
        ability.MARKETING,
        ability.SATC
      ],
      resource: 'Common'
    },
    {
      id: 'summary-user-commerces',
      title: 'Resumen',

      action: [
        ability.MANAGER,
        ability.FINANCE,
        ability.SALES,
        ability.MARKETING,
        ability.SATC
      ],
      resource: 'Common',
      icon: <Activity size={20} />,
      navLink: '/commerces/admin-dashboard'
    },
    {
      id: 'reports-parking',
      title: 'Reportes',

      action: [
        ability.MANAGER,
        ability.FINANCE,
        ability.SALES,
        ability.MARKETING,
        ability.SATC
      ],
      resource: 'Common',
      icon: <Download size={20} />,
      navLink: '/parking-lot/reports'
    },
    // {
    //   id: 'close-bulding-parking',
    //   title: 'Cierres building',

    //   action: 'pagodirecto-level-2',
    //   resource: 'Common',
    //   icon: <FileText size={20} />
    //   // navLink: 'commerces/logout'
    // },
    {
      header: 'Transacciones',
      action: [ability.MANAGER, ability.SATC, ability.ATC],
      resource: 'Common'
    },
    {
      id: 'buldig-close-parking',
      title: 'Historial de tickets',

      action: [ability.MANAGER, ability.SATC, ability.ATC],
      resource: 'Common',
      icon: <List size={20} />,
      navLink: 'parking-lot/tickets-history'
    },
    {
      header: 'Estacionamientos',
      action: [ability.MANAGER, ability.SATC, ability.ATC],
      resource: 'Common'
    },
    {
      id: 'digitize-tickets-parking',
      title: 'Digitalizar tickets',
      action: [ability.MANAGER, ability.SATC, ability.ATC],
      resource: 'Common',
      icon: digitalizeTicket,
      navLink: 'parking-lot/tickets-digitize'
    },
    {
      id: 'register-parking',
      title: 'Registrar taquilla',
      action: [ability.MANAGER, ability.SATC, ability.SYSTEM],
      resource: 'Common',
      icon: sspossIcon,
      navLink: 'parking-lot/box-office'
    },
    {
      header: 'Tickets físicos',
      action: [ability.MANAGER, ability.PROMOTER, ability.SATC, ability.ATC],
      resource: 'Common'
    },
    {
      id: 'pay-tickets-parking',
      title: 'Pagar tickets',
      action: [ability.MANAGER, ability.PROMOTER, ability.SATC, ability.ATC],
      resource: 'Common',
      icon: billIcon,
      navLink: 'parking-lot/tickets-pay'
    },

    {
      id: 'ticket-info-parking',
      title: 'Ticket físico info',

      action: [ability.MANAGER, ability.PROMOTER, ability.SATC, ability.ATC],
      resource: 'Common',
      icon: <File size={20} />,
      navLink: 'parking-lot/validate-ticket'
    },
    { header: 'Ajustes', action: [ability.MANAGER], resource: 'Common' },
    {
      id: 'list-builings-parking',
      title: 'Listado buildings',

      action: [ability.MANAGER],
      resource: 'Common',
      icon: buildingIcon,
      navLink: 'parking-lot/buildings'
    },

    // {
    //   id: 'create-building-parking',
    //   title: 'Crear building',

    //   action: 'pagodirecto-level-2',
    //   resource: 'Common',
    //   icon: <FileText size={20} />
    //   // navLink: 'commerces/logout'
    // },
    {
      header: 'En tiempo real',
      action: [ability.MANAGER, ability.PROMOTER, ability.SATC, ability.ATC],
      resource: 'Common'
    },
    {
      id: 'connection-reports-parking',
      title: 'Informes de conexión',

      action: [ability.MANAGER, ability.PROMOTER, ability.SATC, ability.ATC],
      resource: 'Common',
      icon: <Bluetooth size={20} />,
      navLink: '/parking-lot/connection-reports'
    },
    {
      header: 'Premium',
      action: [
        ability.MANAGER,
        ability.SALES,
        ability.MARKETING,
        ability.SATC,
        ability.ATC
      ],
      resource: 'Common'
    },
    {
      id: 'available-premium-parking',
      title: 'Activar',

      action: [ability.MANAGER, ability.SATC],
      resource: 'Common',
      icon: <CheckSquare size={20} />,
      navLink: '/parking-lot/premium/activation'
    },
    {
      id: 'history-premium-parking',
      title: 'Historial',

      action: [
        ability.MANAGER,
        ability.SALES,
        ability.MARKETING,
        ability.SATC,
        ability.ATC
      ],
      resource: 'Common',
      icon: <FileText size={20} />,
      navLink: '/parking-lot/premium/history'
    },
    {
      header: 'Configuración',
      action: [
        ability.MANAGER,
        ability.SALES,
        ability.MARKETING,
        ability.ATC,
        ability.SATC,
        ability.FINANCE,
        ability.SYSTEM,
        ability.PROMOTER
      ],
      resource: 'Common'
    },
    {
      id: 'logout-parking',
      title: 'Cerrar sesión',

      action: [
        ability.MANAGER,
        ability.SALES,
        ability.MARKETING,
        ability.ATC,
        ability.SATC,
        ability.FINANCE,
        ability.SYSTEM,
        ability.PROMOTER
      ],
      resource: 'Common',
      icon: <LogOut size={12} />,
      navLink: 'commerces/logout'
    },
    {
      id: 'span-a-parking',
      title: '',

      action: 'pagodirecto-level-0',
      resource: 'Common',
      navLink: 'commerces/logout'
    }
  ],
  label: 'Parking',
  isActive: false,
  homeRoute: '/parking-lot/tickets-history'
}

const parkingVertical = {
  vertical: [
    {
      header: 'Estadísticas',
      action: [
        ability.MANAGER,
        ability.FINANCE,
        ability.SALES,
        ability.MARKETING,
        ability.SATC
      ],
      resource: 'Common'
    },
    {
      id: 'summary-parking-meters',
      title: 'Resumen',
      action: [
        ability.MANAGER,
        ability.FINANCE,
        ability.SALES,
        ability.MARKETING,
        ability.SATC
      ],
      resource: 'Common',
      icon: <PieChart size={20} />,
      navLink: '/commerces/dashboard'
    },
    {
      id: 'reports-parking-meters',
      title: 'Reportes',

      action: [
        ability.MANAGER,
        ability.FINANCE,
        ability.SALES,
        ability.MARKETING,
        ability.SATC
      ],
      resource: 'Common',
      icon: <Download size={20} />,
      navLink: 'parking-meters/reports-files'
    },

    {
      header: 'Transacciones',
      action: [ability.MANAGER, ability.SATC, ability.ATC],
      resource: 'Common'
    },
    {
      id: 'ticket-history-parking-meters',
      title: 'Historial de tickets',

      action: [ability.MANAGER, ability.SATC, ability.ATC],
      resource: 'Common',
      icon: <FileText size={20} />,
      navLink: 'parking-meters/transactions'
    },
    {
      id: 'penalty-parking-meters',
      title: 'Penalizaciones',

      action: [ability.MANAGER, ability.SATC, ability.ATC],
      resource: 'Common',
      icon: <AlertOctagon size={20} />,
      navLink: 'parking-meters/penalty'
    },
    {
      id: 'report-parking-meters',
      title: 'Reportes',

      action: [ability.MANAGER, ability.SATC, ability.ATC],
      resource: 'Common',
      icon: <FileText size={20} />,
      navLink: 'parking-meters/reports'
    },

    {
      header: 'Ajustes',
      action: [ability.MANAGER, ability.SALES, ability.SATC],
      resource: 'Common'
    },
    {
      id: 'create-entity-parking-meters',
      title: 'Listado entidades',

      action: [ability.MANAGER, ability.SALES, ability.SATC],
      resource: 'Common',
      icon: <FileText size={20} />,
      navLink: 'parking-meters/entity-list'
    },
    {
      id: 'create-area-parking-meters',
      title: 'Listado áreas',

      action: [ability.MANAGER, ability.SALES, ability.SATC],
      resource: 'Common',
      icon: <FileText size={20} />,
      navLink: 'parking-meters/area-list'
    },
    {
      id: 'create-zone-parking-meters',
      title: 'Listado zonas',

      action: [ability.MANAGER, ability.SALES, ability.SATC],
      resource: 'Common',
      icon: <FileText size={20} />,
      navLink: 'parking-meters/zones-list'
    },
    {
      id: 'create-subzone-parking-meters',
      title: 'Listado parquímetros',

      action: [ability.MANAGER, ability.SALES, ability.SATC],
      resource: 'Common',
      icon: <FileText size={20} />,
      navLink: 'parking-meters/subzone-list'
    },

    {
      id: 'change-rate-parking-lot',
      title: 'Compañías asociadas',

      action: [ability.MANAGER, ability.SALES, ability.SATC],
      resource: 'Common',
      icon: <FileText size={20} />,
      navLink: 'parking-meters/associated-company'
    },

    {
      header: 'Configuración',
      action: [
        ability.MANAGER,
        ability.SALES,
        ability.MARKETING,
        ability.ATC,
        ability.SATC,
        ability.FINANCE
      ],
      resource: 'Common'
    },
    {
      id: 'logout-parking-meters',
      title: 'Cerrar sesión',

      action: [
        ability.MANAGER,
        ability.SALES,
        ability.MARKETING,
        ability.ATC,
        ability.SATC,
        ability.FINANCE
      ],
      resource: 'Common',
      icon: <LogOut size={12} />,
      navLink: 'commerces/logout'
    }
  ],
  label: 'Parquimetros',
  isActive: false,
  homeRoute: '/parking-meters/transactions'
}

const tollVertical = {
  vertical: [
    {
      header: 'Estadísticas',
      action: [
        ability.MANAGER,
        ability.SALES,
        ability.MARKETING,
        ability.FINANCE
      ],
      resource: 'Common'
    },
    {
      id: 'summary-tolls',
      title: 'Resumen',

      action: [
        ability.MANAGER,
        ability.SALES,
        ability.MARKETING,
        ability.FINANCE
      ],
      resource: 'Common',
      icon: <PieChart size={20} />,
      navLink: '/commerces/dashboard'
    },
    {
      id: 'reports-tolls',
      title: 'Reportes',
      action: [
        ability.MANAGER,
        ability.SALES,
        ability.MARKETING,
        ability.FINANCE
      ],
      action: [
        ability.MANAGER,
        ability.SALES,
        ability.MARKETING,
        ability.FINANCE
      ],
      resource: 'Common',
      icon: <Download size={20} />,
      navLink: '/tolls/tolls-reports'
    },

    {
      header: 'Transacciones',
      action: [ability.MANAGER, ability.ATC, ability.SATC],
      resource: 'Common'
    },

    {
      id: 'trip-history-tolls',
      title: 'Historial de viajes',

      action: [ability.MANAGER, ability.ATC, ability.SATC],
      resource: 'Common',
      icon: <FileText size={20} />,
      navLink: '/tolls/tickets/list'
    },

    {
      id: 'bundles-tolls',
      title: 'Paquetes',

      action: [ability.MANAGER, ability.ATC, ability.SATC],
      resource: 'Common',
      icon: <Package size={20} />,
      navLink: '/tolls/bundles/history'
    },
    {
      header: 'Ajustes',
      action: [ability.MANAGER, ability.SYSTEM, ability.SATC],
      resource: 'Common'
    },
    {
      id: 'access-tolls',
      title: 'Accesos',

      action: [ability.MANAGER, ability.SYSTEM, ability.SATC],
      resource: 'Common',
      icon: <Clipboard size={20} />,
      navLink: '/tolls/access'
    },

    {
      id: 'bundles',
      title: 'Paquetes activos',
      icon: <Package size={20} />,
      action: [ability.MANAGER, ability.SYSTEM, ability.SATC],
      resource: 'Common',
      navLink: '/tolls/bundles/admin'
    },

    // {
    //   id: 'rate-changes-tolls',
    //   title: 'Cambio de tarifas',

    //   action: 'pagodirecto-level-2',
    //   resource: 'Common',
    //   icon: <BookOpen size={20} />
    //   // navLink: '/commerces/dashboard'
    // },

    {
      id: 'buildings-list-tolls',
      title: 'Listado de peajes',

      action: [ability.MANAGER, ability.SYSTEM, ability.SATC],
      resource: 'Common',
      icon: <Package size={20} />,
      navLink: '/tolls/tolls'
    },
    {
      header: 'Configuración',
      action: [
        ability.MANAGER,
        ability.SALES,
        ability.MARKETING,
        ability.ATC,
        ability.SATC,
        ability.FINANCE,
        ability.SYSTEM
      ],
      resource: 'Common'
    },
    {
      id: 'logout-toll',
      title: 'Cerrar sesión',

      action: [
        ability.MANAGER,
        ability.SALES,
        ability.MARKETING,
        ability.ATC,
        ability.SATC,
        ability.FINANCE,
        ability.SYSTEM
      ],
      resource: 'Common',
      icon: <LogOut size={12} />,
      navLink: 'commerces/logout'
    }
  ],
  label: 'Peaje',
  isActive: false,
  homeRoute: '/commerces/dashboard',
  reportsOptions: []
}

const serviceVertical = {
  vertical: [
    {
      header: 'Estadísticas',
      action: [ability.MANAGER, ability.SALES],
      resource: 'Common'
    },
    {
      id: 'summary-service',
      title: 'Resumen',

      action: [ability.MANAGER, ability.SALES],
      resource: 'Common',
      icon: <PieChart size={20} />,
      navLink: '/commerces/dashboard'
    },
    {
      id: 'reports-service',
      title: 'Reportes',

      action: [ability.MANAGER, ability.SALES],
      resource: 'Common',
      icon: <Download size={20} />,
      navLink: 'services/service-reports'
    },

    {
      header: 'Configuración',
      action: [ability.MANAGER, ability.SALES],
      resource: 'Common'
    },
    {
      id: 'logout-service',
      title: 'Cerrar sesión',

      action: [ability.MANAGER, ability.SALES],
      resource: 'Common',
      icon: <LogOut size={12} />,
      navLink: 'commerces/logout'
    }
  ],
  label: 'Servicios',
  homeRoute: '/services/service-reports',
  isActive: false
}

const insuranceVertical = {
  vertical: [
    {
      header: 'Seguro',
      action: [
        ability.MANAGER,
        ability.SALES,
        ability.MARKETING,
        ability.ATC,
        ability.SATC
      ],
      resource: 'Common'
    },
    {
      id: 'insurance-dashboard-service',
      title: 'Resumen',

      action: [
        ability.MANAGER,
        ability.SALES,
        ability.MARKETING,
        ability.ATC,
        ability.SATC
      ],
      resource: 'Common',
      icon: <PieChart size={12} />,
      navLink: 'insurance/dashboard'
    },
    {
      id: 'insurance-history-service',
      title: 'Historial transacciones',

      action: [
        ability.MANAGER,
        ability.SALES,
        ability.MARKETING,
        ability.ATC,
        ability.SATC
      ],
      resource: 'Common',
      icon: transactionsIcon,
      navLink: 'insurance/transactions'
    },
    {
      id: 'insurance-report-service',
      title: 'Reportes',

      action: [
        ability.MANAGER,
        ability.SALES,
        ability.MARKETING,
        ability.ATC,
        ability.SATC
      ],
      resource: 'Common',
      icon: <FileText size={12} />,
      navLink: 'insurance/insurance-reports'
    },
    {
      header: 'Configuración',
      action: [
        ability.MANAGER,
        ability.SALES,
        ability.MARKETING,
        ability.ATC,
        ability.SATC
      ],
      resource: 'Common'
    },
    {
      id: 'logout-service',
      title: 'Cerrar sesión',

      action: [
        ability.MANAGER,
        ability.SALES,
        ability.MARKETING,
        ability.ATC,
        ability.SATC
      ],
      resource: 'Common',
      icon: <LogOut size={12} />,
      navLink: 'commerces/logout'
    }
  ],
  label: 'Seguro',
  homeRoute: '/insurance/dashboard',
  isActive: false
}

const AdminVertical = {
  vertical: [
    {
      header: 'Administrador de comercios',
      action: [ability.MANAGER],
      resource: 'Common'
    },
    {
      id: 'pd-work-team',
      title: 'Equipo de trabajo',

      action: [ability.MANAGER],
      resource: 'Common',
      icon: <User size={20} />,
      navLink: '/commerces/work-team'
    },

    {
      header: 'Información',
      action: 'pagodirecto-level-2',
      resource: 'Common'
    },

    {
      id: 'pd-support-team',
      title: 'Soporte',

      action: 'pagodirecto-level-2',
      resource: 'Common',
      icon: <User size={20} />,
      navLink: '/superadmin/support-info'
    },

    {
      header: 'Configuración',
      action: [ability.MANAGER],
      resource: 'Common'
    },
    {
      id: 'logout-admin',
      title: 'Cerrar sesión',

      action: [ability.MANAGER],
      resource: 'Common',
      icon: <LogOut size={12} />,
      navLink: 'commerces/logout'
    }
  ],
  label: 'Administración',
  homeRoute: '/commerces/work-team',
  isActive: false
}

export const initialState = {
  loading: false,

  verticalOptions: [
    { value: 'parking', label: 'Estacionamientos' },
    { value: 'service', label: 'Servicios' },
    { value: 'parkingmeter', label: 'Parquimetros' },
    { value: 'toll', label: 'Peaje' }
  ],

  servicesOptions: [
    { value: 'cantv', label: 'Cantv' },
    { value: 'digitel', label: 'Digitel' },
    { value: 'inter', label: 'Inter' },
    { value: 'movistar', label: 'Movistar' },
    { value: 'simpletv', label: 'Simpletv' }
  ],

  buildings: [],

  reportsUsersOptions,
  reportsParkingLotOptions,
  reportsCommercesOptions,
  reportsParkingMetersOptions,
  reportsAdministrativeOptions,
  reportsServicesOptions,
  reportsTollsOptions,

  navbarOptions: [
    userVertical,
    eventsVertical,
    walletVertical,
    commercesVertical,
    posVertical,
    parkingLotVertical,
    parkingVertical,
    tollVertical,
    serviceVertical,
    insuranceVertical,
    AdminVertical
  ],

  supportInfo: null,
  sidebarOptions: [...userVertical.vertical],
  isSuperadmin: false
}
