import { end } from '@popperjs/core'

export const deafultFilter = {
  filterOptions: {
    campaign_name: ''
  },
  pagination: {
    page: 1,
    limit: 10
  }
}

export const statusOptions = {
  active: { label: 'Activo', color: 'light-success' },
  completed: { label: 'Completado', color: 'light-primary' },
  inactive: { label: 'Inactivo', color: 'light-secondary' },
  deleted: { label: 'Inactivo', color: 'light-secondary' }
}

const defaultActions = {
  add: { action: 'add', message: 'Agregar publicidad' },
  update: { action: 'update', message: 'Actualizar publicidad' },
  updateMonthly: {
    action: 'updateMonthly',
    message: 'Actualizar servicio mensual'
  },
  updateStatus: { action: 'updateStatus', message: 'actualizar' },
  updateImages: { action: 'updateImages', message: 'Actualizar imágenes' },
  showImages: { action: 'showImages', message: 'Ver imágenes' },
  delete: { action: 'deleted', message: 'eliminar' },
  updatePercentages: { action: 'updatePercentages', message: 'actualizar' }
}

const handleErrors = {
  campaign_name: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  url: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  contracted_views: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    },
    // value musth be greater than 0
    min: {
      value: 0,
      message: 'El valor debe ser mayor a 0'
    }
  },

  vertical_types: {
    validate: (value) => value.length !== null,
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  service_names: {
    validate: (value) => value.length !== null,
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  commerce_id: {
    validate: (value) =>
      value.value !== null && value.value !== undefined && value.value !== '',
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  click_cost: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  reaction_cost: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  view_cost: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },

  expiration_date: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  }
}

const defaultValues = {
  status: 'active',
  contracted_views: '',
  url: '',
  campaign_name: '',
  commerce_id: '',
  vertical_types: '',
  service_names: '',
  click_cost: '',
  reaction_cost: '',
  view_cost: '',
  type: '',
  monthly_cost: '',
  fromDate: '',
  endDate: ''
}

const advertisingType = {
  monthly: 'Periodo de tiempo',
  fixed: 'Alcance'
}

const daysOptions = [
  { label: 'Día', value: 'daily' },
  { label: 'Semana', value: 'weekly' },
  { label: 'Mes', value: 'monthly' },
  { label: 'Rango de fecha', value: 'custom' }
]

const initialState = {
  loading: false,
  loadingUsers: false,

  daysOptions,

  advertisingType,

  data: [],
  advertisingOptions: [],

  filters: deafultFilter,
  handleErrors,
  defaultValues,

  selectedAdvertising: null,

  nextPageExists: false,
  actions: defaultActions,

  servicesOptions: [
    { value: 'cantv', label: 'Cantv' },
    { value: 'corpoelec', label: 'Corpoelec' },
    { value: 'movilnet', label: 'Movilnet' },
    { value: 'digitel', label: 'Digitel' },
    { value: 'inter', label: 'Inter' },
    { value: 'movistar', label: 'Movistar' },
    { value: 'simpletv', label: 'Simpletv' },
    { value: 'netuno', label: 'Netuno' }
  ],

  statusOptions: [
    { value: 'active', label: 'Activo' },
    { value: 'inactive', label: 'Inactivo' }
  ]
}

export default initialState
