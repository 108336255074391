import { createSlice } from '@reduxjs/toolkit'
import initialState from './initialState'

export const slice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setTransactions: (state, action) => {
      state.data = action.payload
    },
    setTransactionsFilters: (state, action) => {
      state.filters = action.payload
    },
    setNextPageExists: (state, action) => {
      state.nextPageExists = action.payload
    },
    setSelectedTransaction: (state, action) => {
      state.selectedTransaction = action.payload
    },
    setTransactionTagsOptions: (state, action) => {
      state.transactionTagsOptions = action.payload
    },
    setTagsOptions: (state, action) => {
      state.tagsOptions = action.payload
    },
    setDescriptionTagsOptions: (state, action) => {
      state.descriptionTagsOptions = action.payload
    },
    setSupportInfo: (state, action) => {
      state.supportInfo = action.payload
    }
  }
})

export const {
  setLoading,
  setTransactions,
  setTransactionsFilters,
  setNextPageExists,
  setSelectedTransaction,
  setTransactionTagsOptions,
  setDescriptionTagsOptions,
  setTagsOptions,
  setSupportInfo
} = slice.actions

export const transactions = slice.reducer
