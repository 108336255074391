import { current } from '@reduxjs/toolkit'
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

export const defaultFilter = {
  pagination: {
    limit: 10,
    page: 1
  },
  sorting: {
    field: 'created_at',
    direction: 'DESC'
  },
  input: '',
  filterOptions: {
    input: '',
    category: '',
    type: ''
  }
}

export const defaultCommercesAssociatesFilters = {
  from_date: '',
  to_date: '',
  commerces_list: [],
  pagination: {
    limit: 10,
    page: 1
  }
}

export const defaultExtensionsFilters = {
  pagination: {
    limit: 10,
    page: 1
  },
  commerce_id: '',
  filterOptions: {
    input: ''
  }
}

const defaultValues = {
  pre_username: '',
  username: '',
  custom_name: '',
  name: '',
  short_name: '',
  email: '',
  doc_type: 'J',
  doc_id: '',
  phone: '',
  local_number: '',
  address: '',
  category: '',
  building_id: ''
}

const initialCommerce = () => {
  const item = window.localStorage.getItem('selectedCommerce')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

const defaultActions = {
  add: { action: 'add', message: 'Agregar acceso' },
  updateFee: { action: 'updateFee', message: 'Actualizar tarifas' },
  update: { action: 'update', message: 'Actualizar acceso' },
  delete: { action: 'deleted', message: 'Eliminar acceso' }
}

const defaultActionsCommerces = {
  add: { action: 'add', message: 'Agregar comercios' },
  updateFee: { action: 'updateFee', message: 'Actualizar tarifas' },
  update: { action: 'update', message: 'Actualizar comercios' },
  delete: { action: 'deleted', message: 'Eliminar comercios' },
  blocked: { action: 'blocked', message: 'actualizar' },

  enableExtensions: { action: 'enableExtensions', message: 'actualizar' },
  rechargeFounds: { action: 'rechargeFounds', message: 'Recargar fondos' },
  whiteList: {
    action: 'whiteList',
    message: 'Comercios habilitados para consumir extensiones'
  },
  assignCommerce: {
    action: 'assignCommerce',
    message: 'Comercios asociados'
  }
}

const defaultActionsCommercesExtensions = {
  add: { action: 'add', message: 'Agregar extensiones' },
  update: { action: 'update', message: 'Actualizar extensiones' },
  delete: { action: 'deleted', message: 'Eliminar extensiones' },
  exportFile: { action: 'exportFile', message: 'exportar' },
  loadFile: { action: 'loadFile', message: 'Cargar documento' },
  updateStatus: { action: 'updateStatus', message: 'actualizar' }
}

const handleErrors = {
  pre_username: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  username: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  custom_name: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  name: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  short_name: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  email: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    },
    pattern: {
      value: /^\S+@\S+$/i,
      message: 'Correo invalido'
    }
  },

  doc_id: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  phone: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  local_number: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  address: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  category: {
    validate: (value) =>
      value.value !== null && value.value !== undefined && value.value !== '',
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  }
}

const daysOptions = [
  { label: 'Mes', value: 'monthly' },
  { label: 'Rango de fecha', value: 'custom' }
]

export const statusOptions = {
  active: { label: 'Activo', color: 'light-success' },
  blocked: { label: 'Bloqueado', color: 'light-secondary' },
  inactive: { label: 'Bloqueado', color: 'light-secondary' },
  deleted: { label: 'Inactivo', color: 'light-secondary' }
}
const initialState = {
  currentCommerce: null,
  commerceMobilePaid: null,
  associatedCommercesTransaction: [],
  loading: false,
  defaultValues,
  filters: defaultFilter,
  filtersExtensions: defaultExtensionsFilters,
  filtersCommercesAssociate: defaultCommercesAssociatesFilters,
  actions: defaultActions,
  actionsCommerces: defaultActionsCommerces,
  actionsCommercesExtensions: defaultActionsCommercesExtensions,
  nextPageExists: false,
  handleErrors,
  selectedCommerce: initialCommerce(),
  selectedCommerceInfo: null,
  selectedCommerceExtension: null,
  commerces: [],
  data: [],
  commercesOptions: [],
  commercesBancamigaBankOptions: [],
  commercesOtherBankOptions: [],
  commercesTypesOptions: [],
  dashboardForm: {
    startDay: moment().startOf('day').format('YYYY-MM-DD'),
    period: 'daily'
  },
  dashboardLoading: false,
  amountsLoading: false,
  dashboardData: [],
  amounts: {
    amount: 0,
    amountByQty: 0,
    qty: 0
  },
  statusOptions,

  commerceAssociatesOptions: [],
  daysOptions,

  temporalXAxis: [],
  temporalYAxisTotal: [],
  temporalYAxisAmount: [],
  totalAmount: 0,

  totalCommercesAmount: 0,
  totalQty: 0,

  selectedCommerceRoles: []
}

export default initialState
